import { ref } from 'vue';

export const audioSrc: { [key: string]: string } = {
  AGREEMENT: require('src/assets/assessment/audio/0.mp3'),
  CODE: require('src/assets/assessment/audio/1.mp3'),
  CODE_ERROR: require('src/assets/assessment/audio/2.mp3'),
  NAME: require('src/assets/assessment/audio/3.mp3'),
  PHONE: require('src/assets/assessment/audio/4.mp3'),
  BIRTH: require('src/assets/assessment/audio/5.mp3'),
  GENDER: require('src/assets/assessment/audio/6.mp3'),
  EDUCATION: require('src/assets/assessment/audio/7.mp3'),
  USER_INFO_FINISH: require('src/assets/assessment/audio/8.mp3'),

  NUMERIC_INFO: require('src/assets/assessment/audio/10.mp3'),
  NUMBER_EX: require('src/assets/assessment/audio/10-1.mp3'),
  NUMERIC_FINISH: require('src/assets/assessment/audio/10-2.mp3'),

  STROOP_INFO: require('src/assets/assessment/audio/11.mp3'),
  STROOP_EX: require('src/assets/assessment/audio/11-1.mp3'),
  STROOP_FINISH: require('src/assets/assessment/audio/11-2.mp3'),

  PRACTICE_CORRECT: require('src/assets/assessment/audio/20.mp3'),
  PRACTICE_WRONG: require('src/assets/assessment/audio/21.mp3'),

  REMEMBER_INFO: require('src/assets/assessment/audio/12.mp3'),
  REMEMBER_EX: require('src/assets/assessment/audio/12-1.mp3'),
  REMEMBER_ANSWER_CLICK: require('src/assets/assessment/audio/12-2.mp3'),
  REMEMBER_FINISH: require('src/assets/assessment/audio/12-3.mp3'),

  MEMORIZE_INFO: require('src/assets/assessment/audio/13.mp3'),
  MIX_READ: require('src/assets/assessment/audio/13-1.mp3'),
  MIX_READ_TWO: require('src/assets/assessment/audio/13-2.mp3'),
  MIX_CALC: require('src/assets/assessment/audio/13-3.mp3'),
  MIX_REPEAT: require('src/assets/assessment/audio/13-4.mp3'),
  MIX_FINISH: require('src/assets/assessment/audio/22.mp3'),

  TOTAL_FINISH: require('src/assets/assessment/audio/23.mp3'),
};

function pathToSrc(path: string) {
  switch (path) {
    case '/assessment/user-info/code':
      return 'CODE';
    case '/assessment/user-info/name':
      return 'NAME';
    case '/assessment/user-info/phone':
      return 'PHONE';
    case '/assessment/user-info/birth':
      return 'BIRTH';
    case '/assessment/user-info/gender':
      return 'GENDER';
    case '/assessment/user-info/education':
      return 'EDUCATION';
    case '/assessment/assessment-info/numeric/info':
      return 'NUMERIC_INFO';
    case '/assessment/numeric/practice':
      return 'NUMBER_EX';
    case '/assessment/assessment-info/numeric/practice-correct':
      return 'PRACTICE_CORRECT';
    case '/assessment/assessment-info/numeric/practice-wrong':
      return 'PRACTICE_WRONG';
    case '/assessment/numeric/test':
      return 'NUMBER_EX';
    case '/assessment/assessment-info/numeric/finish':
      return 'NUMERIC_FINISH';
    case '/assessment/assessment-info/stroop/info':
      return 'STROOP_INFO';
    case '/assessment/stroop/practice':
      return 'STROOP_EX';
    case '/assessment/assessment-info/stroop/practice-correct':
      return 'PRACTICE_CORRECT';
    case '/assessment/assessment-info/stroop/practice-wrong':
      return 'PRACTICE_WRONG';
    case '/assessment/stroop/test':
      return 'STROOP_EX';
    case '/assessment/assessment-info/stroop/finish':
      return 'STROOP_FINISH';
    case '/assessment/assessment-info/remember/info':
      return 'REMEMBER_INFO';
    case '/assessment/remember/practice-question':
      return 'REMEMBER_EX';
    case '/assessment/remember/practice-answer':
      return 'REMEMBER_ANSWER_CLICK';
    case '/assessment/assessment-info/remember/practice-correct':
      return 'PRACTICE_CORRECT';
    case '/assessment/assessment-info/remember/practice-wrong':
      return 'PRACTICE_WRONG';
    case '/assessment/remember/test-question':
      return 'REMEMBER_EX';
    case '/assessment/remember/test-answer':
      return 'REMEMBER_ANSWER_CLICK';
    case '/assessment/assessment-info/remember/finish':
      return 'REMEMBER_FINISH';
    case '/assessment/memorize/choose-bigger':
      return 'MIX_CALC';
    case '/assessment/assessment-info/memorize/finish':
      return 'MIX_FINISH';
    case '/assessment/assessment-info/total/finish':
      return 'TOTAL_FINISH';
    default:
      return '';
  }
}

export default function useAudio() {
  const audio = ref(new Audio());
  const playState = ref(false);

  const play = (audioKey: string, isPath?: boolean) => {
    let src: string = audioKey;
    if (isPath) src = pathToSrc(audioKey);
    if (src.length < 1) return;

    audio.value.pause();
    audio.value.src = audioSrc[src];
    audio.value.load();
    const playRes = audio.value.play();

    if (playRes !== undefined) {
      playRes
        .catch((error) => {
          console.log(error);
          if (error.name === 'NotAllowedError') {
            // NotAllowedError(소리 출력 권한 없음 에러) 발생 시
          } else {
            // Handle a load or playback error
          }
        })
        .then(() => {
          // Start whatever you need to do only after playback
          // has begun.
        });
    }
  };

  const pause = () => {
    audio.value.pause();
    playState.value = false;
  };

  return { play, pause };
}
